import SigninContent from "./login";
import Heading from "../header/top";
import Footer from "../footer/footer";

export default function Login(){
    return(
        <div>
            <Heading />
            <SigninContent />
            <Footer />
        </div>
        
    )
    
}